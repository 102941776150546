import $ from "jquery";
import env from "bnc-env";
import comAuth from "bnc-auth";
import utils from "utils";
import Vue from "vue";
import bncSecurity from "bnc-security";
import bncToast from "bnc-toast";
import icheck from "icheck";
import bncProtocol from "bnc-protocol";
import bncImBiz from "bnc-im-biz";
import bncVcode from "bnc-verification-code";

let vm = new Vue({
    el: "#container-img",
    data: {
        form: {
            pcode: "",
        },
        isAggreInput: true,
        leftArr: [],
        loaded: false,
        isShowPwd: false, //给密码加明文暗纹
    },
    watch: {
        isAggreInput(newVal, oldVal) {
            if (newVal) {
                $(".i-checks").iCheck("check");
            } else {
                $(".i-checks").iCheck("uncheck");
            }
            pageManager.loginStateControl();
        },
    },
    methods: {
        popupPrivacy: function (state) {
            bncProtocol.bind(state, false);
        },
    },
    mounted() {
        this.loaded = true;
        setTimeout(() => {
            this.$refs.accountInput.focus(); // 自动聚焦账号输入框
            $("#loginbtn").removeAttr("disabled");
        }, 100);
    },
    created() {
        switch (urls.currentProjectEnv) {
            case "anxun":
                this.leftArr = [
                    {
                        url: "",
                        text: "© 2019-2025 浙江安迅应急救援科技有限公司",
                    },
                ];
                break;
            case "dfyx":
                this.leftArr = [
                    {
                        url: "https://beian.miit.gov.cn",
                        text: "豫ICP备20001968号",
                    },
                    {
                        url: "",
                        text: "京公网安备11010802022657号",
                    },
                ];
                break;
            case "light":
                this.leftArr = [
                    {
                        url: "https://beian.miit.gov.cn",
                        text: "浙ICP备2022011631号-1",
                    },
                    {
                        url: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33108202000717",
                        text: "浙公网安备 33108202000717号",
                        needIcon: true,
                    },
                ];
                document.title = "吉灵网络";
                break;
            case "huanke":
                this.leftArr = [
                    {
                        url: "https://beian.miit.gov.cn",
                        text: "Copyright@2024  浙江博信数智科技有限公司 浙ICP备2024060958号-3",
                    },
                ];
                break;

            default:
                this.leftArr = [
                    {
                        url: "https://beian.miit.gov.cn",
                        text: "京ICP备18063915号-1",
                    },
                    {
                        url: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402013355",
                        text: "京公网安备 11011402013355号",
                        needIcon: true,
                    },
                ];
                break;
        }
    },
});
/**
 * 页面参数
 */
const pageManager = {
    loginType: 1,
    //大市场的初始化状态
    initBigMarketStatus: false,
    findData: function () {
        let param = {
            authtype: this.loginType,
        };
        if (param.authtype === 1) {
            param.principal = $("#passwordForm [name='username']").val();
            param.credentials = $("#passwordForm [name='password']").val();
            /*记住登录状态*/
            param.rememberMe = $("#rememberpwd").is(":checked");
        } else {
            param.principal = $("#vcodeForm [name='phonenumber']").val();
            param.credentials = $("#vcodeForm [name='vcode']").val();
            /*记住登录状态*/
            param.rememberMe = $("#remembersms").is(":checked");
        }
        return param;
    },

    validatePhone: function (phone) {
        return phone && /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[13589])[0-9]{8}$/.test(phone);
    },
    /**
     * 验证:TODO 后面再做标准组件修改
     */
    validate: function (data) {
        let r = false;
        if (!data) {
            bncToast.errorAlert("输入的信息不能为空");
        } else if (utils.checkIsNullOrEmpty(data.principal)) {
            bncToast.errorAlert("手机号不能为空");
        } else if (!this.validatePhone(data.principal)) {
            bncToast.errorAlert("手机号格式错误");
        } else if (utils.checkIsNullOrEmpty(data.credentials)) {
            bncToast.errorAlert(data.authtype === 1 ? "密码不能为空" : "验证码不能为空");
        } else {
            r = true;
        }
        return r;
    },
    loginStateControl: function () {
        let isShowLoginBtn = true; //控制登录按钮是否可用
        let param = {};
        let btnSelecter = pageManager.loginType === 1 ? "#loginbtn" : "#loginbtn_two";
        param.username = $(pageManager.loginType === 1 ? "#username" : "#phonenumber").val();
        param.password = $(pageManager.loginType === 1 ? "#password" : "#vcode").val();
        for (let key in param) {
            if (param[key] === "") {
                isShowLoginBtn = false;
                break;
            } else {
                isShowLoginBtn = true;
            }
        }
        if (isShowLoginBtn && vm.isAggreInput) {
            $(btnSelecter).removeAttr("disabled");
        } else {
            $(btnSelecter).attr("disabled", true);
        }
    },
    /**
     * 提交
     */
    login: function () {
        console.log("getMarketInfo");
        let param = this.findData();

        if (this.validate(param)) {
            comAuth.auth(param, async function (res) {
                if (res.code === 0) {
                    let redirect = utils.getQueryString("redirect", true);
                    let newUrl = location.origin + "/bnc_market/market/#/personal-center";
                    let initBigMarketStatus = await getMarketInfo();

                    console.log("----------------initBigMarketStatus", initBigMarketStatus);
                    // let newUrl = "https://dev130.bncwork.com/bnc_market/market/#/personal-center";
                    location.href = redirect
                        ? decodeURIComponent(redirect)
                        : initBigMarketStatus
                        ? newUrl
                        : "../my-home/index.html";
                } else {
                    bncToast.success(res.msg);
                }
            });
        }
    },
};

initDataAndUI();
initEvent();
// getMarketInfo();

function initDataAndUI() {
    $("#logo").attr("src", env.restUrl("/logo/logo/default"));
}
function getMarketInfo() {
    return new Promise((r, j) => {
        env.doAjax("/market/manage/operate/settlement/card").then(
            res => {
                console.log("获取市场", res);
                if (res.code === 0) {
                    // if (!res.data) {
                    //     pageManager.initBigMarketStatus = false;
                    //     return
                    // } else {
                    //     pageManager.initBigMarketStatus = true;
                    // }
                    r(!!res.data);
                } else {
                    r(false);
                }
            },
            () => {
                r(false);
            }
        );
    });
}
function initEvent() {
    // 回到首页
    $("#gohome")
        .unbind()
        .bind("click", function () {
            window.location.href = env.frontUrl("/module/bcenterbiz/index.html");
        });
    // 绑定回车提交
    $("#passwordForm input").bind("keydown", function (e) {
        if (e.keyCode == "13") {
            pageManager.login();
        }
    });
    $("#loginbtn").on("click", function () {
        pageManager.login();
    });
    $("#loginbtn_two").on("click", function () {
        pageManager.login();
    });
    // 输入框聚焦清空提示
    $("input").bind("input propertychange", function () {
        $(".error").text("");
    });
    // 输入框聚焦时去除readonly，加readonly的目的是禁用网页记住密码；输入手机号和密码失焦时，便于判断登录按钮的状态
    $("#passwordForm").on("focus", "input", function () {
        pageManager.loginStateControl();
        $(this).removeAttr("readonly");
        console.log("聚焦");
    });
    $("#passwordForm,#vcodeForm").on("input", "input", function () {
        console.log(1);
        pageManager.loginStateControl();
    });
    // 改变登录方式
    $("#changeLogin")
        .unbind()
        .bind("click", function () {
            if (pageManager.loginType == 1) {
                $("form").removeClass("d-none").filter("#passwordForm").addClass("d-none");
                $("#changeLogin span").text("使用密码登录");
                $("#changeLogin img").attr("src", "../../../assets/img/login_password.png");
                $("#loginText").text("验证码登录");
                pageManager.loginType = 2;
            } else {
                $("form").removeClass("d-none").filter("#vcodeForm").addClass("d-none");
                $("#changeLogin span").text("使用验证码登录");
                $("#loginText").text("密码登录");
                $("#changeLogin img").attr("src", "../../../assets/img/login-cut.png");
                pageManager.loginType = 1;
            }
            $(".error").text("");
            $("input").val("");
        });
    // 发送验证码
    $("#sendvcode")
        .unbind()
        .bind("click", function () {
            let data = pageManager.findData();
            data.credentials = "****";
            if (pageManager.validate(data)) {
                bncVcode.open(
                    {
                        type: 1,
                        phone: data.principal,
                        pcode: "",
                    },
                    "#sendvcode",
                    function (ret) {
                        if (ret.code === 0) {
                            bncToast.success("验证码已发送");
                        } else {
                            bncToast.errorAlert(ret.msg);
                        }
                    }
                );
            }
        });
}

$(document).ready(function () {
    $(".i-checks").iCheck({
        checkboxClass: "icheckbox_square-blue",
        radioClass: "iradio_square-blue",
    });
    $(".i-checks").on("ifChanged", function (event) {
        if ($(event.target).is(":checked")) {
            vm.isAggreInput = true;
        } else {
            vm.isAggreInput = false;
        }
    });
});
